@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
body {
  font-family: "poppins";
}

.font-family {
  font-family: "rubik";
}

.gplay-font {
  font-family: "montserrat";
}

.slick-prev:before,
.slick-next:before {
  color: #127ee1;
}

.slick-selected {
  transform: scale(1);
  border: 0;
  background: linear-gradient(to bottom, #127ee1, #1550bd);
  color: white;
  z-index: 10;
}

.slick-slide {
  overflow: hidden;
}

.slick-slide:has(.slick-selected) {
  overflow: visible;
}

.slick-dots {
  position: relative;
  bottom: -10px;
}

.slick-dots li.slick-active button:before {
  color: #1550bd;
  opacity: 100;
  font-size: large;
}

.slick-dots li button:before {
  color: #1550bd;
  font-size: small;
  transition: all;
}

.slick-dots li button:before:hover {
  font-size: large;
}
